import { ref } from 'vue'
import { useStore } from 'vuex'
import {
  getCustomerLabels,
  addCustomer,
  addCustomerLabel,
  editCustomerLabel,
  deleteCustomerLabel
} from '@service/CustomerService'
import { schemaMaker, reqString, validateForms } from '@valid/JoiValidation'

const labelHandler = () => {
  // Necessary items
  const store = useStore()
  const labels = ref(null)
  const labelAction = ref('add')
  const labelName = ref('')
  const currentLabel = ref(null)
  const labelBtnLoading = ref(false)
  const labelModal = ref(false)
  const newLabelModal = ref(false)
  const deleteLabelModal = ref(false)

  // Get labels api call
  const getLabels = async () => {
    try {
      const { data } = await getCustomerLabels()
      labels.value = data.results
    } catch (ex) {
      if (ex.response) {
        console.log(ex.response.status)
      }
    }
  }

  // Change label of a customer
  const changeLabel = async (id, label) => {
    // id belongs to customer
    try {
      await addCustomer(id, { label: label.id })

      store.dispatch('addToast', {
        type: 'success',
        message: 'گروه مشتری با موفقیت تغییر کرد.'
      })
      store.dispatch('customer/fetchItem', { part: 'customer', id })
    } catch (ex) {
      if (ex.response) {
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت آمیز نبود.'
        })
      }
    } finally {
      labelModal.value = false
    }
  }

  // New label validation schema
  const validLabel = schemaMaker({
    name: reqString('نام گروه')
  })

  // Add label api call
  const addLabel = async () => {
    if (labelBtnLoading.value) return

    const errors = validateForms({ name: labelName.value }, validLabel)

    if (errors && errors.length > 0) {
      for (let key of errors) {
        const message = { type: 'error', message: key }
        store.dispatch('addToast', message)
      }
      return
    }

    labelBtnLoading.value = true

    try {
      await addCustomerLabel({ name: labelName.value })

      store.dispatch('addToast', {
        type: 'success',
        message: 'گروه با موفقیت اضافه گردید.'
      })
      getLabels()
      labelName.value = ''
      newLabelModal.value = false
      labelModal.value = true
    } catch (ex) {
      if (ex.response) {
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت آمیز نبود.'
        })
      }
    } finally {
      labelBtnLoading.value = false
    }
  }

  // Edit label api call
  const editLabel = async () => {
    if (labelBtnLoading.value) return
    // get items
    const errors = validateForms({ name: labelName.value }, validLabel)

    if (errors && errors.length > 0) {
      for (let key of errors) {
        const message = { type: 'error', message: key }
        store.dispatch('addToast', message)
      }
      return
    }

    labelBtnLoading.value = true

    try {
      await editCustomerLabel({
        id: currentLabel.value.id,
        name: labelName.value
      })

      store.dispatch('addToast', {
        type: 'success',
        message: 'نام گروه با موفقیت تغییر کرد.'
      })
      getLabels()
      labelName.value = ''
      newLabelModal.value = false
      labelModal.value = true
    } catch (ex) {
      if (ex.response) {
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت آمیز نبود.'
        })
      }
    } finally {
      labelBtnLoading.value = false
    }
  }

  // Edit label api call
  const deleteLabel = async (id) => {
    if (labelBtnLoading.value) return

    labelBtnLoading.value = true
    try {
      await deleteCustomerLabel({ id })

      store.dispatch('addToast', {
        type: 'success',
        message: 'گروه با موفقیت حذف گردید.'
      })
      getLabels()
      deleteLabelModal.value = false
    } catch (ex) {
      if (ex.response) {
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت آمیز نبود.'
        })
      }
    } finally {
      labelBtnLoading.value = false
    }
  }

  return {
    labels,
    getLabels,
    changeLabel,
    labelAction,
    labelName,
    currentLabel,
    labelBtnLoading,
    addLabel,
    editLabel,
    deleteLabel,
    labelModal,
    newLabelModal,
    deleteLabelModal
  }
}

export default labelHandler
