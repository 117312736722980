<template>
  <div
    class="input-wrapper"
    :style="{ width: width, marginBottom: mb }"
    :class="{
      light: lightTheme,
      'start-left': startLeft,
      'customer-number': customerNumber,
      phone09: phone09,
      rial: rial || endText
    }"
  >
    <div class="input-data">
      <input
        required
        autocomplete="off"
        :pattern="inputType === 'number' ? '[0-9]*' : '.*'"
        :type="device == 'other-devices' ? inputType : ''"
        :value="modelValue"
        @input="updateValue"
        :style="{ paddingRight: pr }"
      />
      <label :class="[animateType, starLabel ? 'star' : '']">
        {{ label }}</label
      >
      <span class="error" v-if="error">{{ error }}</span>
      <span v-if="rial">ریال</span>
      <span class="end-text" v-if="endText" v-text="endText" />
      <span v-if="phone09">09</span>
      <div class="underline" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurInput',
  props: {
    modelValue: {
      type: [String, Number]
    },
    label: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '80%'
    },
    mb: {
      type: String,
      default: '10px'
    },
    inputType: {
      type: String,
      default: 'text'
    },
    animateType: {
      type: String,
      default: 'remove'
    },
    rial: {
      type: Boolean,
      default: false
    },
    lightTheme: {
      type: Boolean,
      default: false
    },
    startLeft: {
      type: Boolean,
      default: false
    },
    customerNumber: {
      type: Boolean,
      default: false
    },
    phone09: {
      type: Boolean,
      default: false
    },
    endText: {
      type: String
    },
    pr: {
      type: String,
      default: '4px'
    },
    charCountToRemove: {
      type: Number,
      default: 5
    },
    starLabel: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue($event) {
      this.$emit('update:modelValue', $event.target.value)
    }
  },
  watch: {
    modelValue(newValue) {
      const el = this.$el.querySelector('label')
      if (this.customerNumber) {
        if (newValue.length > 4) {
          el.classList.add('remove')
        } else {
          el.classList.remove('remove')
        }
      }
      if (this.endText) {
        if (newValue.length > this.charCountToRemove) {
          el.classList.add('remove')
        } else {
          el.classList.remove('remove')
        }
      }
      if (this.rial) {
        if (newValue.length > this.charCountToRemove) {
          el.classList.add('remove')
        } else {
          el.classList.remove('remove')
        }
      }
    }
  },
  computed: {
    device() {
      return this.$store.state.device
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  margin: 12px 0;
  .input-data {
    width: 100%;
    height: 40px;
    position: relative;

    label {
      position: absolute;
      font-size: 15px;
      top: 12px;
      right: 5px;
      color: #b4b4b4;
      pointer-events: none;
      transition: all 0.5s ease;
      &.star {
        // bottom: 0px;
        &::before {
          position: relative;
          content: '*';
          color: #c69d4e;
          font-size: 20px;
          top: 6px;
          margin-left: 3px;
        }
      }
    }

    input {
      font-size: 17px;
      height: 100%;
      width: 100%;
      color: #000;
      background-color: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid black;
      text-align: right;
      padding: 2px 4px 0px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type='number'] {
        -moz-appearance: textfield;
      }

      &:focus {
        ~ div.underline::before {
          transform: translateX(0);
        }
      }
      &:focus,
      &:valid {
        ~ label.decrease {
          transform: translate(3px, -28px);
          font-size: 15px;
        }
        ~ label.remove {
          opacity: 0;
        }
      }
    }

    .error {
      position: absolute;
      color: #d40000;
      right: 15px;
      bottom: -23px;
      font-size: 12px;
      &::before {
        position: absolute;
        content: '';
        right: -15px;
        bottom: 3px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #d40000;
      }
    }

    .underline {
      overflow: hidden;
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 2px;
      z-index: 2;

      &::before {
        content: '';
        position: absolute;
        background-color: #c69d4e;
        width: 100%;
        right: 0;
        height: 100%;
        transform: translateX(100%);
        transition: transform 0.5s ease;
      }
    }
  }
  &.light .input-data {
    $light-color: #444;
    input {
      border-bottom: 1px solid $light-color;
      color: white;
    }

    .rial {
      color: $light-color;
    }
  }

  &.start-left .input-data input {
    direction: ltr;
    text-align: left;
  }

  &.customer-number {
    .input-data {
      height: 45px;
      input {
        font-size: 42px;
      }
    }
  }
  &.phone09 {
    .input-data {
      input {
        padding-left: 50px;
      }
      span {
        position: absolute;
        left: 0;
        font-size: 42px;
        bottom: -10px;
        color: #000;
        pointer-events: none;
      }
    }
  }
  &.rial .input-data {
    input {
      padding-left: 33px;
      font-size: 25px;
    }
    span {
      position: absolute;
      left: 0;
      font-size: 15px;
      bottom: 8px;
      color: #000;
      pointer-events: none;
    }
    .end-text {
      bottom: 9px;
    }
  }
}
</style>
