<template>
  <router-link :to="{ name: 'CustomerShow', params: { id: customerId } }">
    <div class="customer-info">
      <div class="customer-img">
        <img v-show="flip" @load="flip = true" :src="img" />
        <svg v-if="!flip" viewBox="0 0 512 512">
          <circle fill="#606062" cx="256" cy="256" r="256" />
          <path
            fill="#373435"
            d="M201.7 317.66c0,-7.43 -5.22,-13.42 -8.58,-18.06l-11.06 -14.74c-3.33,-4.39 -6.88,-10.74 -9.9,-15.86 -18.6,-31.45 -26.08,-64.92 -23.63,-104.73 1.99,-32.33 19.08,-65.1 47.19,-82.47 52.07,-32.16 134.88,-19.01 160.12,46.69 17.89,46.58 8.74,98.98 -15.73,141.53 -9.12,15.85 -29.58,32.83 -28.81,52.12 0.34,8.53 4.38,12 10.35,15.84 13.28,8.56 50.2,19.39 74,33.64 9.62,5.76 23.16,13.75 31.83,21.68 17.72,16.19 19.6,26.57 22.27,30.03 38.79,-44.88 62.25,-103.36 62.25,-167.33 0,-141.38 -114.62,-256 -256,-256 -141.39,0 -256,114.62 -256,256 0,64.18 23.62,122.85 62.65,167.78 1.67,-3.25 2.68,-6.15 4.75,-9.45 2.22,-3.53 4.12,-5.95 6.62,-8.84 18.93,-21.98 65.14,-46.57 93.07,-59.06 17.47,-7.81 34.61,-9.68 34.61,-28.77z"
          />
        </svg>
      </div>
      <h4 class="customer__name" v-text="name" />
      <h5 class="customer__rate" v-text="group" />
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ProfileButton',
  props: {
    customerId: [String, Number],
    name: String,
    group: String,
    img: String
  },
  data() {
    return {
      flip: false
    }
  }
}
</script>

<style scoped lang="scss">
a {
  display: flex;
  width: 100%;
  .customer-info {
    cursor: pointer;
    border-radius: 15px 15px 0 0;
    $img-margin: 10px;
    padding: 10px;
    height: 100px;
    width: 100%;
    background-color: #eee;
    display: grid;
    grid-template-columns: 80px auto;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    margin: 0;
    .customer-img {
      grid-column: 1 / 2;
      grid-row: 1/-1;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // border-radius: 100%;
      // background-color: rgba(#fff, 0.05);
      img {
        border-radius: 100%;
        width: 80px;
        height: 80px;
        filter: saturate(0.3);
        box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
      }
      svg {
        border-radius: 100%;
        width: 80px;
        height: 80px;
        box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
      }
    }
    $margin-to-center: 5px;
    .customer__name {
      grid-column: 2 / 3;
      grid-row: 1/2;
      margin: 0 15px $margin-to-center 0;
      align-self: end;
      width: 100%;
      color: #000;
      text-align: right;
      font-size: 18px;
    }
    .customer__rate {
      grid-column: 2 / 3;
      grid-row: 2/3;
      align-self: start;
      margin: $margin-to-center 15px 0 0;
      width: 100%;
      color: #777;
      text-align: right;
      font-size: 16px;
    }
  }
}
</style>
